import React, { useCallback } from 'react';

import { useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';
import { useUpdateUser } from 'hooks/useUpdateUser/useUpdateUser';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';
import { BasicUserAccount } from 'api/types/account';
import { Button } from 'ui/button/Button';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { UpdateAppointmentUserFormData } from './updateAppointmentUserForm/UpdateAppointmentUserForm.types';
import { UpdateAppointmentUserForm } from './updateAppointmentUserForm/UpdateAppointmentUserForm';
import { useStyles } from './UpdateAppointmentUserButton.styles';

export function UpdateAppointmentUserButton({ user }: { user: BasicUserAccount }) {
  const styles = useStyles();
  const appointmentsDispatch = useAppointmentsDispatch();
  const updateUserAccount = useUpdateUser();
  const setMessage = useMessagesDispatch();
  const setDialog = useDialogDispatch();

  const handleSubmit = useCallback(
    async (values: UpdateAppointmentUserFormData) => {
      const updated = await updateUserAccount(values);
      if (updated) {
        setDialog({ open: false });
        setMessage({ message: 'Account successfully updated!', type: 'success' });
        appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
      } else {
      }
    },
    [appointmentsDispatch, setDialog, setMessage, updateUserAccount],
  );

  const handleDialog = () => {
    setDialog({
      open: true,
      dialogContent: <UpdateAppointmentUserForm user={user} onSubmit={handleSubmit} />,
    });
  };

  return (
    <div>
      <Button buttonType="filled" size="small" className={styles.button} onClick={() => handleDialog()} disabled>
        Edit info
      </Button>
    </div>
  );
}
